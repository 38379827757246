<template>
    <div class="scene_teaching_page">
        <div class="">
            <CourseTitle :title="title" :contText="contText"> </CourseTitle>
            <div class="">
                <div class="navBox display">
                    <div class="list display pointer" :class="navIndex == index ? 'active' : ''"
                        v-for="(item, index) in navList" :key="index" @click="changeBtn(index, item)">
                        {{ item.name }}
                    </div>
                </div>
                <div class="carousel_box ">
                    <el-carousel :interval="5000" :autoplay="false">
                        <el-carousel-item v-for="(list, index) in faceTeaching" :key="index">
                            <div class="serve_customer flex content">
                                <div class="list" v-for="(item, index2) in list" :key="index2">
                                    <img class="swiper_img" :src="item.faceTeachingImgUrl" alt="">
                                    <div class="name display">{{ item.faceTeachingName }}</div>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CourseTitle from '../views/homes/components/courseTitle.vue'
export default {
    props: {
        title: {},
        contText: {},
        faceTeaching: {}
    },
    components: {
        CourseTitle,
    },
    data() {
        return {
            teaching: [],

        }
    },
    mounted() { },
    methods: {

    }

}
</script>
<style lang="scss" scoped>
.scene_teaching_page {
    width: 1400px;
    margin: 0 auto;
    padding-bottom: 100px;

    .carousel_box {
        margin-top: 40px;

        ::v-deep.swiper-container {
            height: 336px;
            padding-left: 3px;
        }

        ::v-deep.el-carousel__container {
            height: 340px;
        }

        ::v-deep.el-carousel {
            .el-carousel__item {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .el-carousel__container {
                .el-carousel__arrow {
                    width: 48px;
                    height: 48px;
                    font-size: 24px;
                    background: rgba(51, 51, 51, 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

            }
        }

        .list:nth-child(1) {
            margin-left: 0 !important;
        }

        .list {
            width: 360px;
            height: 334px;
            margin: 0px 30px;
            box-shadow: 0px 1px 5px 0px rgba(64, 64, 64, 0.25);

            .swiper_img {
                width: 361px;
                height: 260px;
            }

            .name {
                width: 100%;
                height: 70px;
                background: #fff;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
            }
        }
    }

    .navBox {
        margin-top: 22px;

        .list {
            height: 48px;
            background: #FAFAFA;
            border: 1px solid #DEDEDE;
            border-radius: 24px;
            padding: 0 38px;
            margin: 0 11px
        }

        .active {
            background: #003C7E !important;
            color: white !important;
        }
    }
}
</style>
<style lang="scss"></style>