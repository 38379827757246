<template>
    <div class="examination_process_page flex flex-wrap">
        <div class="examination_process_list flex-center column" v-for="(item,index) in examinationProcess" :key="index">
            <img :src="item.applicationProcessImgUrl" alt="">
            <img v-if="index == 0 || index == 1 || index == 2 " class="icon_jt" src="@/assets/img/course/jt1.png" alt="">
           
            <img v-else-if="index == 3" class="icon_jt1" src="@/assets/img/course/jt2.png" alt="">

             <img v-else class="icon_jt" src="@/assets/img/course/jt3.png" alt="" />
            <div class="name">{{item.applicationProcessName}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        examinationProcess:{}
    },
    data(){
        return{
           
        }
    }
}
</script>
<style lang="scss" scoped>
.examination_process_page{
    margin-top: 21px;
    .examination_process_list:nth-child(4n+1){
        margin-left: 0 !important;
    }
    .examination_process_list:last-child{
        .icon_jt{
            display: none !important;
        }
    }
    .examination_process_list{
        width: 180px;
        height: 120px;
        background: #FFFFFF;
        box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.15);
        padding-top: 20px;
        border-radius: 8px;
        margin-left: 148px;
        margin-bottom: 70px;
        position: relative;
        img{
            width: 49px;
            height: 49px;
        }
        .icon_jt{
            width: 38px;
            height: 20px;
            position: absolute;
            top: 55px;
            right: -90px;
        }
        .icon_jt1{
            width: 20px;
            height: 38px;
            position: absolute;
            bottom: -50px;
        }
        .name{
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-top: 14px;
            line-height: 18px;
        }
    }
}
</style>