<template>
	<div class="college_work_page">
		<div class="students_work">
			<CourseTitle :title="title" :contText="contText"> </CourseTitle>
			<div class="carousel_box">
				<div class="module_box content">
					<el-carousel :interval="5000" arrow="always">
						<el-carousel-item
							v-for="(item, index) in studentsWork"
							:key="index"
						>
							<div class="el_carousel_cont flex">
								<div class="el_carousel_cont_fl">
									<img :src="item.coverImgUrl" alt="" />
								</div>
								<div class="el_carousel_cont_fr">
									<div class="title">{{ item.studentWorksName }}</div>
									<div class="list" v-html="item.studentWorksContent"></div>

									<div class="construction_img flex">
										<img
											v-for="(items, indexs) in item.studentWorksImgUrl"
											:key="indexs"
											:src="items"
											alt=""
										/>
									</div>
								</div>
							</div>
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import CourseTitle from '../views/homes/components/courseTitle.vue'
export default {
	props: {
		title: {},
		contText: {},
		studentsWork: {},
	},
	components: {
		CourseTitle,
	},
	data() {
		return {}
	},
}
</script>
<style lang="scss" scoped>
.college_work_page {
	height: 780px;
	background: url('../assets/img/student/bg1.png') no-repeat;
	background-size: 100% 100%;
	padding-top: 12px;
	.carousel_box {
		margin-top: 31px;
	}
	.module_box {
		width: 1400px;
		::v-deep.el-carousel__container {
			height: 486px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		::v-deep.el-carousel {
			.el-carousel__item {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.el-carousel__container {
				.el-carousel__arrow {
					width: 48px;
					height: 48px;
					font-size: 24px;
					background: rgba(51, 51, 51, 0.5);
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

		.el_carousel_cont {
			width: 1208px;
			height: 486px;
			background: #fafafa;
			border-radius: 20px;
			padding: 26px 24px;
			box-sizing: border-box;

			.el_carousel_cont_fl {
				img {
					width: 640px;
					height: 430px;
					margin-right: 30px;
				}
			}

			.el_carousel_cont_fr {
				.title {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #333333;
					line-height: 20px;
					margin-top: 20px;
					margin-bottom: 27px;
				}

				.list {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
					margin-bottom: 17px;
					.label {
					}

					.studentName:last-child {
						span {
							display: none;
						}
					}
				}
				.construction_img {
					margin-top: 26px;
					img {
						width: 222px;
						height: 177px;
						margin-right: 17px;
					}
				}
			}
		}
	}
}
</style>