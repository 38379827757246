<template>
    <div class="constructionEngineering_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle :title="formData.whyStudyName" contText=""> </CourseTitle>
            <div class="whyShouldLearn content">
                <div class="whyShouldLearn_div_top whyShouldLearn_div flex"
                    v-if="formData.architectureWhyStudyVOS.length != 0">
                    <div class="fl">
                        <div class="fl_title flex-center">
                            <img class="icon_img" src="@/assets/img/course/01.png" alt="">
                            <div class="name">{{ formData.architectureWhyStudyVOS[0].whyStudyName }}</div>
                        </div>
                        <div class="richText" v-html="formData.architectureWhyStudyVOS[0].whyStudyIntroduce">

                        </div>
                        <div class="learnMore display pointer" @click="service">了解更多</div>
                    </div>
                    <div class="fr">
                        <img :src="formData.architectureWhyStudyVOS[0].whyStudyImgUrl" alt="" />
                    </div>
                </div>
                <div class="whyShouldLearn_div whyShouldLearn_div_bottom flex">
                    <div class="fl">
                        <img :src="formData.architectureWhyStudyVOS[1].whyStudyImgUrl" alt="" />

                    </div>
                    <div class="fr">
                        <div class="fl_title flex-center">
                            <img class="icon_img" src="@/assets/img/course/02.png" alt="">
                            <div class="name">{{ formData.architectureWhyStudyVOS[1].whyStudyName }}</div>
                        </div>
                        <div class="richText" v-html="formData.architectureWhyStudyVOS[1].whyStudyIntroduce">

                        </div>
                        <div class="learnMore display pointer" @click="service">了解更多</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module2">
            <div class="content">
                <CourseTitle :title="formData.industryTalentNeedsName" contText=""> </CourseTitle>
                <img :src="formData.industryTalentNeedsImgUrl" alt="">
            </div>
        </div>
        <div class="module3">
            <CoreCourses :coreCourseTitle="formData.coreCourseTitle"
                :registrationCoreCourse="formData.architectureCoreCourseVOS"
                :coreCourses="formData.architectureCoreCourseVOS[0]"></CoreCourses>
        </div>
        <div class="certificate_box">
            <CourseTitle :title="'证书样式'" contText=""> </CourseTitle>
            <div class="content display">
                <img class="certificate_img" :src="formData.certificateUrl" alt="">
            </div>
        </div>
        <div class="module4">
            <div class="content">
                <CourseTitle :title="formData.applicationGuideName" contText=""> </CourseTitle>
                <div class="signUp_content flex flex-wrap">
                    <div class="signUp_list pointer flex-center column"
                        v-for="(item, index) in formData.architectureApplicationGuideVOS" :key="index">
                        <img :src="item.applicationGuideImgUrl" alt="">
                        <div class="signUp_name">{{ item.applicationGuideName }}</div>
                        <div class="viewBtn display " @click="service">查看详情</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module5">
            <div class="content">
                <CourseTitle :title="formData.applicationProcessName" :isWhite="1" contText=""> </CourseTitle>
                <ExaminationProcess :examinationProcess="formData.examinationProcess"></ExaminationProcess>
            </div>
        </div>
        <div class="module6">
            <div class="">
                <CourseTitle :title="formData.teachingTeamName" :contText="formData.teachingTeamIntroduce"> </CourseTitle>
                <Lecturer :lecturerList="formData.teacherVOS"></Lecturer>
            </div>
        </div>
        <div class="module7">
            <LearningMaterials :type="3" :options="formData.architectureTeachingMaterialsVOS"
                :courseTitle="formData.teachingMaterialsName"></LearningMaterials>
        </div>
        <div class="module8">
            <div class="content">
                <CourseTitle :title="formData.preparationMaterialsName" contText=""> </CourseTitle>
                <CollectData :collectList="formData.architecturePreparationMaterialsVOS"></CollectData>
            </div>
        </div>
        <div class="module9">
            <ExaminationInformation :title="formData.applicationConsultationName"
                :contText="formData.applicationConsultationIntroduce"
                :province="formData.architectureApplicationConsultationProvinceVOS"
                :question="formData.architectureApplicationConsultationQuestionVOS"></ExaminationInformation>
        </div>
        <div class="module10">
            <SceneTeaching :title="formData.faceTeachingName" :contText="formData.faceTeachingIntroduce"
                :faceTeaching="formData.architectureFaceTeachingVOS"></SceneTeaching>
        </div>
        <div class="module11">
            <CollegeWork :title="formData.studentWorksName" :contText="formData.studentWorksIntroduce"
                :studentsWork="formData.architectureStudentWorksVOS"></CollegeWork>
        </div>
        <div class="module12">
            <div class="content">
                <CourseTitle :title="formData.employmentServicesName" contText=""> </CourseTitle>
                <div class="employment_services flex">
                    <img class="icon_tag" src="@/assets/img/course/tag.png" alt="" />
                    <div @mouseover="mouseOver(index)" class="list flex-center column pointer"
                        v-for="(item, index) in formData.architectureEmploymentServicesVOS" :key="index"
                        @click="changeBtn(item, index)">
                        <div class="employment_services_name display" :class="activeIndex == index ? 'active' : ''">
                            {{ item.employmentServicesName }}</div>
                        <div class="employment_services_introduce">{{ item.employmentServicesIntroduce }}
                        </div>
                        <img v-if="activeIndex == index" class="icon_point" src="@/assets/img/course/point.png" alt="">
                        <div class="img_box" style="overflow:hidden;">
                            <img class="employment_services_img" :src="item.employmentServicesImgUrl" alt="" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="module13">
            <SignUp :courseType="3" :courseId="this.formData.id"></SignUp>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
import CoreCourses from '@/components/coreCourses'
import ExaminationProcess from '@/components/examinationProcess'
import Lecturer from '@/components/lecturer'
import LearningMaterials from '@/components/learningMaterials'
import CollectData from '@/components/collectData'
import ExaminationInformation from '@/components/examinationInformation'
import SignUp from '@/components/signUp'
import SceneTeaching from '@/components/sceneTeaching'
import CollegeWork from '@/components/collegeWork'

export default {
    components: {
        BannerImg,
        CourseTitle,
        CoreCourses,
        ExaminationProcess,
        Lecturer,
        LearningMaterials,
        CollectData,
        ExaminationInformation,
        SceneTeaching,
        SignUp,
        CollegeWork,
    },
    data() {
        return {
            activeIndex: 0,
            formData: {},
            employmentServices: [],

        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
        this.init()
    },
    methods: {
        //获取初始化数据
        init() {
            this.api.findArchitectureWorksInfo().then(res => {
                if (res.code == 0) {
                    res.data.architectureTeachingMaterialsVOS.forEach(item => {
                        item.value = item.id
                        item.label = item.teachingMaterialsName
                    })
                    res.data.architectureStudentWorksVOS.forEach(item => {
                        item.studentWorksImgUrl = item.studentWorksImgUrl.split(',')
                    })
                    this.formData = res.data
                    let arr = []
                    let arr_s = []
                    arr = this.formData.architectureApplicationProcessVOS.slice(0, 4)
                    arr_s = this.formData.architectureApplicationProcessVOS.slice(4)
                    arr_s = arr_s.reverse()
                    this.formData.examinationProcess = [...arr, ...arr_s]
                    this.formData.architectureFaceTeachingVOS = this.carousel(this.formData.architectureFaceTeachingVOS, 3)
                }
            })
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
        // 轮播图格式化
        carousel(dataList, num) {
            let newDataList = []
            let current = 0
            if (dataList && dataList.length > 0) {
                for (let i = 0; i <= dataList.length - 1; i++) {
                    if (i % num !== 0 || i === 0) {
                        if (!newDataList[current]) {
                            newDataList.push([dataList[i]])
                        } else {
                            newDataList[current].push(dataList[i])
                        }
                    } else {
                        current++
                        newDataList.push([dataList[i]])
                    }
                }

            }
            return dataList = [...newDataList]
        },
        //点击高亮
        changeBtn(item, index) {
            this.activeIndex = index
        },
        mouseOver(index) {
            this.activeIndex = index
        }

    }
}
</script>
<style lang="scss" scoped>
.constructionEngineering_page {
    .module1 {

        background: url('../../assets/img/course/bg14.png') no-repeat;
        background-size: 100% 100%;
        padding-bottom: 80px;

        .whyShouldLearn {
            margin-top: 40px;

            .icon_img {
                width: 72px;
                height: 42px;
            }

            .whyShouldLearn_div {
                .name {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    margin-left: 18px;
                }

                .learnMore {
                    width: 168px;
                    height: 48px;
                    background: #FF881E;
                    border-radius: 8px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-top: 68px;
                }

                .richText {
                    width: 528px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    margin-top: 34px;
                    line-height: 24px;
                }

                .fl {
                    padding-top: 35px;
                }
            }

            .whyShouldLearn_div_top .fr {
                margin-left: 43px;

                img {
                    width: 530px;
                    height: 570px;
                }
            }

            .whyShouldLearn_div_bottom {
                margin-top: 116px;

                .fl {
                    padding-top: 0;

                    img {
                        width: 530px;
                        height: 420px;
                    }
                }

                .fr {
                    padding-top: 20px;
                    margin-left: 80px;
                }
            }
        }
    }

    .module2 {
        padding-bottom: 123px;

        img {
            width: 1200px;
            height: 453px;
            margin-top: 31px;
        }
    }

    .module4 {
        .signUp_content {
            margin-top: 21px;
            margin-bottom: 30px;

            .signUp_list:nth-child(5n+1) {
                margin-left: 0px !important;
            }

            .signUp_list:hover {
                background: #fafafa;
                box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
            }

            .signUp_list {
                width: 218px;
                height: 247px;
                margin-left: 26px;
                margin-bottom: 30px;

                img {
                    width: 90px;
                    height: 90px;
                    margin-top: 30px;
                }

                .signUp_name {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    margin-top: 19px;
                }

                .viewBtn {
                    width: 130px;
                    height: 38px;
                    border: 1px solid #00438C;
                    border-radius: 5px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #00438C;
                    margin-top: 27px;
                }
            }
        }
    }

    .module5 {
        background: url('../../assets/img/course/bg7.png') no-repeat;
        background-size: 100% 100%;
        min-height: 612px;
    }

    .module6 {
        padding-bottom: 120px;
    }

    .module8 {
        padding-bottom: 70px;
    }

    .module10 {
        padding-bottom: 100px;

    }

    .module12 {
        padding-bottom: 98px;

        .employment_services {
            margin-top: 32px;
            position: relative;

            .icon_tag {
                width: 1105px;
                height: 30px;
                position: absolute;
                top: 200px;
                left: 33px;
            }

            .list:nth-child(1) {
                margin-left: 0 !important;

                .icon_point {
                    position: absolute;
                    top: 200px;
                    left: 50px;
                }
            }

            .list:nth-child(2) {
                .icon_point {
                    position: absolute;
                    top: 206px;
                    left: 151px;
                }
            }

            .list:nth-child(3) {
                .icon_point {
                    position: absolute;
                    top: 206px;
                    left: 434px;
                }
            }

            .list:nth-child(4) {
                .icon_point {
                    position: absolute;
                    top: 206px;
                    right: 464px;
                }
            }

            .list:nth-child(5) {
                .icon_point {
                    position: absolute;
                    top: 206px;
                    right: 182px;
                }
            }

            .list:hover {
                .employment_services_name {
                    background: #004895 !important;
                    color: white !important;
                }

                .employment_services_img {
                    transform: scale(1.1);
                }
            }

            .active {
                background: #004895 !important;
                color: white !important;
            }

            .list {
                margin-left: 22px;
                z-index: 6;

                .employment_services_name {
                    width: 190px;
                    height: 55px;
                    background: #FAFAFA;
                    border-radius: 5px;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 20px;

                }

                .icon_point {
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    z-index: 1;
                    position: absolute;
                }

                .employment_services_introduce {
                    width: 240px;
                    height: 92px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 24px;
                    margin-top: 14px;
                    text-align: center;
                }

                .img_box {

                    margin-top: 105px;

                    img {
                        width: 270px;
                        height: 230px;
                    }

                    .employment_services_img {
                        width: 270px;
                        height: 230px;
                        transition: All 0.8s ease;

                    }

                }
            }
        }
    }

    .certificate_box {
        height: 674px;
        background: url("../../assets/img/course/bg19.png") no-repeat;
        background-size: 100% 100%;

        .content {
            .certificate_img {
                width: 900px;
                height: 320px;
                margin-top: 62px;

            }
        }
    }

}</style>
